import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiButton } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "S-Mount Lenses",
  "path": "/Products/Lenses/S-Mount/IN-1200/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IP Camera Lense Upgrades",
  "image": "../../../P_SearchThumb_Products.png",
  "social": "/images/Search/P_SearchThumb_Products.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Lenses_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-1200 S-Mount Lenses' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IP Camera Lense Upgrades' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/Lenses/S-Mount/Lense_Comparison/IN-1200/' locationFR='/fr/Products/Lenses/S-Mount/Lense_Comparison/IN-1200/' crumbLabel="IN-1200" mdxType="BreadCrumbs" />
    <h2 {...{
      "id": "in-1200-tele",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-1200-tele",
        "aria-label": "in 1200 tele permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-1200 Tele`}</h2>
    <p><strong parentName="p">{`Compatible Camera Models`}</strong></p>
    <ul>
      <li parentName="ul">{`IN-3001 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3003 Indoor Camera`}</li>
      <li parentName="ul">{`IN-3005 Indoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-3011/"
        }}>{`IN-3010/1 Indoor Camera`}</a></li>
      <li parentName="ul">{`IN-2901 Outdoor Camera`}</li>
      <li parentName="ul">{`IN-2904 Outdoor Camera`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Outdoor_Cameras/IN-2905_V2/"
        }}>{`IN-2905 V2 Outdoor Camera`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Indoor_Cameras/IN-6012_HD/"
        }}>{`IN-6011/2 HD Indoor Camera`}</a></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "642px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/428735e8858b2a926e45faa7ef4a66ce/1bba8/Angle_of_View_12mm-Lense.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "74.78260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC9klEQVQ4y1WTaW/bRhCG+ed7oEDRFmnjoiiQojZcOEZkI3Zk2BJJkRIpyXItiaR4X+Ihykl6/IOn4OoA+mHA2dnlM+/Mzkryo4Yy0+k9DlCmKspEoT9rYxryk05Puefh/TX3N9f0xyryREGeDsQZdTZkZBgMBhqe5/P28hJJCU3kwEQJxyiByWCpovgGcmiixGPkZ5X7zgUP3Stk30R1tOO+Fk55ep4zn1s02xeSJEVSXAPFM5Bb800Ud8RgrhxjShtbGbTn2sSqpaE4I+TAQLWm3N/b2E5Es91iWTaS4pkczTd3UEdHXajCP0L3e6o7QlkOBNwKfZ5mIWGUsWm2rFwPqe8Z9LwhPXdnajhGjacMbB1tNdxB3dGxgv5KF0A786mqmqquKKuKetPgrFykiT1lka54ii2m0ZzutM/tY4+7hcqtfoPmm+jpDDUYC5WT4Iko8qnqhvW6oChrivIAXCHFwTN1taHZvOB6Pt3uHbcfPvDdD9/zxddf8u03X3HeueBO7RGmCVVZs8nW2CsXczwlzXJKobTBbUsOw4XIsNluGRmm8JM0Q9eHdK6u+fXnX7j+44J3nSuiKKbaNFRphm3ZGObkCBQKnRVSFFlC9vblBU3XGY4MZEXl9OyMk5MTzs7Peeh2GfT6hEkigGWSUawL8qIUsEPJruchxbFDWW3ERpav0fQh7zodfnr9mt9PT3n16kcu3l7ycHNLHqdUzZYiTvb9q/4HFAqTxKWsm2Nw+/KRj58+8/c///Lp819ivd4rqaKExWLJn9NH8lZlVVOWlfi3qje7sUnTYHdjRUkQxkJl6+frQnwPsHVZkWc5oe0QRzFFnArgQWX7UsTYpGkogJ4foKiaCLbZin3mgwklraJ8TR0nVFm+W1e1uJil5TCfL5DWRUaz3ZVl2Q5ZXoipP0BFj4SSfXn1hjwIiRaW8NtzfhChDQ0MY4zkOBbzxVLA2sFcLJcsLYswjI4NLw8K971qx+q3N29Is0ys2/bESYplWfwHASsNAr1yofQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/428735e8858b2a926e45faa7ef4a66ce/e4706/Angle_of_View_12mm-Lense.avif 230w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/d1af7/Angle_of_View_12mm-Lense.avif 460w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/5e9eb/Angle_of_View_12mm-Lense.avif 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/428735e8858b2a926e45faa7ef4a66ce/a0b58/Angle_of_View_12mm-Lense.webp 230w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/bc10c/Angle_of_View_12mm-Lense.webp 460w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/7eb86/Angle_of_View_12mm-Lense.webp 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/428735e8858b2a926e45faa7ef4a66ce/81c8e/Angle_of_View_12mm-Lense.png 230w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/08a84/Angle_of_View_12mm-Lense.png 460w", "/en/static/428735e8858b2a926e45faa7ef4a66ce/1bba8/Angle_of_View_12mm-Lense.png 642w"],
              "sizes": "(max-width: 642px) 100vw, 642px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/428735e8858b2a926e45faa7ef4a66ce/1bba8/Angle_of_View_12mm-Lense.png",
              "alt": "IN-1200 Tele",
              "title": "IN-1200 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The angle of view of the 12.0mm lense. Field Angle (D x H x V): 23.9° x 19.2° x 14.8°.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/800f1d75011bce6eebd7016c4a23cfeb/c08c5/Tele_Lense_12mm_IRcut.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.21739130434784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFBP/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGa12wJhTCf/8QAGxAAAgMAAwAAAAAAAAAAAAAAAAECAxIREyL/2gAIAQEAAQUC50VrIvR1KLnWhUwP/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAEDAQE/AVB//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwF4VH//xAAZEAADAAMAAAAAAAAAAAAAAAAAARECECH/2gAIAQEABj8CLDuKpRLX/8QAGhAAAwEBAQEAAAAAAAAAAAAAAREhADFxYf/aAAgBAQABPyE+nzBBq4jLiLEdke4dQLjnOXKUP3f/2gAMAwEAAgADAAAAELcv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxCtEZ//xAAXEQEAAwAAAAAAAAAAAAAAAAAAAREx/9oACAECAQE/EL6Qv//EABwQAQEBAQADAQEAAAAAAAAAAAERIQAxQVGRof/aAAgBAQABPxA2mmQj4TqBLN+I8759fvRcC0GnF7sQwZXlKBeIe458zhLYt8qf3v/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/800f1d75011bce6eebd7016c4a23cfeb/e4706/Tele_Lense_12mm_IRcut.avif 230w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/d1af7/Tele_Lense_12mm_IRcut.avif 460w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/e6133/Tele_Lense_12mm_IRcut.avif 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/800f1d75011bce6eebd7016c4a23cfeb/a0b58/Tele_Lense_12mm_IRcut.webp 230w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/bc10c/Tele_Lense_12mm_IRcut.webp 460w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/0ba47/Tele_Lense_12mm_IRcut.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/800f1d75011bce6eebd7016c4a23cfeb/e83b4/Tele_Lense_12mm_IRcut.jpg 230w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/e41a8/Tele_Lense_12mm_IRcut.jpg 460w", "/en/static/800f1d75011bce6eebd7016c4a23cfeb/c08c5/Tele_Lense_12mm_IRcut.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/800f1d75011bce6eebd7016c4a23cfeb/c08c5/Tele_Lense_12mm_IRcut.jpg",
              "alt": "IN-1200 Tele",
              "title": "IN-1200 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Test image of the 12.0mm tele lense on a 1/5" CMOS sensor with IRcut filter.`}</p>
    <h2 {...{
      "id": "compare-all-lenses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#compare-all-lenses",
        "aria-label": "compare all lenses permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Compare All Lenses`}</h2>
    <Link to="/Products/Lenses/S-Mount/Objektiv_Vergleich/" mdxType="Link"><EuiButton fill mdxType="EuiButton">Overview</EuiButton></Link>
    <h2 {...{
      "id": "in-1200-tele-lense-default-lense-chart",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#in-1200-tele-lense-default-lense-chart",
        "aria-label": "in 1200 tele lense default lense chart permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-1200 Tele Lense Default Lense Chart`}</h2>
    <p>{`Lense chart for the 6mm lense for the 1/4" CMOS sensor (Width/Hight vs. Distance from the Object).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "641px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/c7dcc/Lense_Chart_12mm_Lense.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABzElEQVQoz2VSTW8UMQydH1mQQKW3SnCAwq1C4kNI/JLCDXFAbX9A22sp7QEJdmEKs9vZbWYyk0nzZccJ2gmkFN7Jjv3sZzsFABARAPgRiFjXdVmW0+l0Pp9/GzGZTKqqQkR/EwU4V56Xs+qn9z7G6L3ve65XMABgjNHJMSbGGEKII8KIou/FxoPN+5trR0eHUl6JXuzvf2BsmVNDoD9GkFJwzodhCCEQUaGUfvv+3cvXrz6dnDQNk1JqLa01uQkRxRitM6xZDnJIgjNZpfBfeijGkB8dOM6bruu8R+uMdfaarLVOThgHzgqJKAQapGhaprVSemh4zfmlEOJattY6NxkJv9VqrXjXdj2XV7ztat4xREh7ytVvyCbyKTwMol7MesG6ftmLNh0i/ietkFLmGFEw1iyW1eyiZO2FUgN5yocJIfxLBoDkA0K9qKbfP7PmQmuVKqakvM5kp0IrMiKS94vL+ZfJ2bw+B3Qp7Mnn/ccYEfH09Gxn583W1pPt7acPHz0+Pv64IrN2Uf74CgCInjwRrT4pACRy+pXGmN293WfPX9y5u7526/b6vY2Dg8PCGJNnds6mhYcQOOdN0zDG2rbNo1lrcVUZnXNKqV/mVN0NoAXF3QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/e4706/Lense_Chart_12mm_Lense.avif 230w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/d1af7/Lense_Chart_12mm_Lense.avif 460w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/e1e16/Lense_Chart_12mm_Lense.avif 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/a0b58/Lense_Chart_12mm_Lense.webp 230w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/bc10c/Lense_Chart_12mm_Lense.webp 460w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/de042/Lense_Chart_12mm_Lense.webp 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/81c8e/Lense_Chart_12mm_Lense.png 230w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/08a84/Lense_Chart_12mm_Lense.png 460w", "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/c7dcc/Lense_Chart_12mm_Lense.png 641w"],
              "sizes": "(max-width: 641px) 100vw, 641px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f6bfd490d733f27d339bd1ca56cdbc9/c7dcc/Lense_Chart_12mm_Lense.png",
              "alt": "IN-1200 Tele",
              "title": "IN-1200 Tele",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sensing Area:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/5" CMOS; 5,08mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Mount:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S-Mount`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focal Length:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12.0 mm (f)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Back Focal Lenght:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8.0 mm`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Aperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Iris:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fixed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Optical Distortion:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`<   -1%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lense Construction:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 Glasses (+IR Filter)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Focus Extent:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20cm - ∞`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Field Angle (D x H x V):`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23.9° x 19.2° x 14.8°`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Weight:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.5 g`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Temperture:`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`-10°C - 50°C`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      